import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    redirectPath: String,
    isRegistering: { type: Boolean, default: false },
  };

  connect() {
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
    
    document.addEventListener("AppleIDSignInOnSuccess", this.handleSuccess);
    document.addEventListener("AppleIDSignInOnFailure", this.handleFailure);
  }

  disconnect() {
    document.removeEventListener("AppleIDSignInOnSuccess", this.handleSuccess);
    document.removeEventListener("AppleIDSignInOnFailure", this.handleFailure);
  }

  handleSuccess(data) {
    let uri = `${this.redirectPathValue}?code=${data.detail.authorization.code}&id_token=${data.detail.authorization.id_token}&state=${data.detail.authorization.state}`;

    if (data.detail.user) {
      if (data.detail.user.email.includes("privaterelay")) {
        this.buttonTarget.disabled = false;
        window.alert(
          "Para que nuestros suplidores puedan contactarte, debes dejar visible tu correo"
        );
        return;
      }
      uri += `&firstName=${data.detail.user.name.firstName}&lastName=${
        data.detail.user.name.lastName || ""
      }`;
    }

    window.location = uri;
  }

  handleFailure(error) {
    this.buttonTarget.disabled = false;
    window.alert("No logramos iniciar sesión con Apple, vuelve a intentarlo");
  }

  init(e) {
    e.preventDefault();
    if (
      this.isRegisteringValue &&
      !window.confirm(
        "Para que nuestros suplidores puedan contactarte, debes dejar visible tu correo"
      )
    ) {
      alert("Es obligatorio compartir tu correo con la plataforma");
      return;
    }
    
    // Prevent multiple clicks
    if (this.buttonTarget.disabled) return;
    this.buttonTarget.disabled = true;
    this.buttonTarget.click();
  }
}
