import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="chat"
export default class extends Controller {
  static targets = ["inner", "input", "scrollButton", "innerScrollButton"];
  static values = { 
    scrollable: { type: Boolean, default: false },
    readUrl: String 
  };

  connect() {
    // Set initial button visibility with a small delay to ensure DOM is ready
    if (this.hasScrollButtonTarget) {
      setTimeout(() => {
        const isNearBottom =
          document.documentElement.scrollHeight -
            window.scrollY -
            window.innerHeight <
          50;
        if (isNearBottom) {
          this.scrollButtonTarget.classList.add("hidden");
          this.innerScrollButtonTarget.classList.remove("flex");
        } else {
          this.innerScrollButtonTarget.classList.add("flex");
          this.scrollButtonTarget.classList.remove("hidden");
        }
      }, 500);
    }

    if (this.hasInnerScrollButtonTarget) {
      setTimeout(() => {
        const innerContainer = this.innerTarget;
        const isNearBottom =
          innerContainer.scrollHeight -
            innerContainer.scrollTop -
            innerContainer.clientHeight <
          50;
        if (isNearBottom) {
          this.innerScrollButtonTarget.classList.add("hidden");
          this.innerScrollButtonTarget.classList.remove("xl:flex");
        } else {
          this.innerScrollButtonTarget.classList.add("xl:flex");
          this.innerScrollButtonTarget.classList.remove("hidden");
        }
      }, 500);
    }

    if (this.hasInnerTarget && this.scrollableValue) {
      const scrollableElement = this.innerTarget;
      const contentElement = this.innerTarget;

      const observer = new MutationObserver((mutations) => {
        this.scrollToBottom();
      });

      const config = {
        childList: true,
        subtree: true,
      };

      observer.observe(contentElement, config);

      this.scrollToBottom();

      // Add scroll event listener to mark messages as read when bottom is reached
      scrollableElement.addEventListener('scroll', () => {
        const isNearBottom =
          scrollableElement.scrollHeight -
            scrollableElement.scrollTop -
            scrollableElement.clientHeight <
          50;
        
        if (isNearBottom && this.hasReadUrlValue) {
          this.markAsRead();
        }
      });
    }

    if (this.scrollableValue) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
        });
      }, 500);
    }

    // Add scroll event listeners if we have scroll buttons
    if (this.hasScrollButtonTarget) {
      window.addEventListener("scroll", this.handleScroll.bind(this));
    }
    if (this.hasInnerScrollButtonTarget) {
      this.innerTarget.addEventListener(
        "scroll",
        this.handleInnerScroll.bind(this),
      );
    }
  }

  disconnect() {
    if (this.hasScrollButtonTarget) {
      window.removeEventListener("scroll", this.handleScroll.bind(this));
    }
    if (this.hasInnerScrollButtonTarget) {
      this.innerTarget.removeEventListener(
        "scroll",
        this.handleInnerScroll.bind(this),
      );
    }
  }

  handleScroll() {
    const isNearBottom =
      document.documentElement.scrollHeight -
        window.scrollY -
        window.innerHeight <
      50;

    if (isNearBottom) {
      this.scrollButtonTarget.classList.add("hidden");
      this.innerScrollButtonTarget.classList.remove("flex");
    } else {
      this.innerScrollButtonTarget.classList.add("flex");
      this.scrollButtonTarget.classList.remove("hidden");
    }
  }

  handleInnerScroll() {
    const innerContainer = this.innerTarget;
    const isNearBottom =
      innerContainer.scrollHeight -
        innerContainer.scrollTop -
        innerContainer.clientHeight <
      50;

    if (isNearBottom) {
      this.innerScrollButtonTarget.classList.add("hidden");
      this.innerScrollButtonTarget.classList.remove("xl:flex");
    } else {
      this.innerScrollButtonTarget.classList.add("xl:flex");
      this.innerScrollButtonTarget.classList.remove("hidden");
    }
  }

  scroll() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });

      if (this.hasInnerTarget)
        this.innerTarget.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
    }, 500);
  }

  scrollToBottom() {
    if (!this.hasInnerTarget) return;

    const scrollableElement = this.innerTarget;
    scrollableElement.scrollTop = scrollableElement.scrollHeight;
  }

  scrollInnerToBottom() {
    if (this.hasInnerTarget) {
      const innerContainer = this.innerTarget;
      innerContainer.scrollTo({
        top: innerContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  async markAsRead() {
    if (!this.hasReadUrlValue) return;
    
    try {
      await post(this.readUrlValue);
    } catch (err) {
      console.log(err);
    }
  }

  clear(e) {
    if (!this.hasInputTarget || !e.detail.success) return;

    this.inputTarget.value = "";
    this.scrollToBottom();
  }

  refocus() {
    setTimeout(() => {
      this.inputTarget.focus();
      this.scroll();
    }, 50);
  }
}
